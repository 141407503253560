import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  EditSharp,
  MoreVert as MoreVertIcon,
  Receipt as ReceiptIcon
} from '@material-ui/icons';
import {
  CircularProgress,
  IconButton,
  Link,
  MenuItem,
  MenuList,
  Popover,
  TextField,
  Typography
} from '@material-ui/core';
import { reverse } from 'named-urls';
import { ConfirmationDialog } from '../../common/confirmation-dialog';
import { closeConsignment } from '../../../services/api';
import { ConsignmentsContext } from '../../../context/consignments';
import { getFileURL } from '../../../services/storage';
import { IMenuItemsProps, ITooltipProps, IConsignmentDocumentUrls } from './model';
import { HTTP_ERROR_MESSAGE, URLS } from '../../../constants';
import { StatusPopup } from '../../common/status-popup';
import { validateVesselNumber } from '../../common/vessel-number-algorithm';
import config from "../../../config";

type initAnchorEl = HTMLDivElement | null;
type initUrl = string | undefined;

const useStyles = makeStyles({
  navLink: {
    width: '100%',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  disabledNavLink: {
    width: '100%',
    textDecoration: 'none',
    opacity: 0.5,
    pointerEvents: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  disabledMenuItem: {
    cursor: 'not-allowed'
  },
  iconButton: {
    color: 'rgba(0, 0, 0, 0.5)'
  }
});

export const Tooltips = (props: ITooltipProps) => {
  const classes = useStyles();
  const {
    closeEvent,
    status,
    consignmentId,
    invoiceKeyIE,
    invoiceKeyUK,
    customsInvoiceKeyIE,
    customsInvoiceKeyUK,
    manifestKeyIE,
    manifestKeyUK,
    transitDocKey,
    isLvc,
    purpose,
    vesselNumber
  } = props;
  const { dispatchConsignments } = useContext(ConsignmentsContext);
  const [error, setError] = React.useState('');
  const [anchorEl, setAnchorEl] = useState(null as initAnchorEl);
  const [consignmentUrl, setConsignmentUrl] = useState('' as initUrl);
  const [confirmationDialogVisibility, setConfirmationDialogVisibility] = useState(false);
  const [urls, setUrls] = useState<IConsignmentDocumentUrls>({
    manifestUrlIE: undefined,
    manifestUrlUK: undefined,
    vatInvoiceUrlIE: undefined,
    vatInvoiceUrlUK: undefined,
    customsInvoiceUrlIE: undefined,
    customsInvoiceUrlUK: undefined,
    transitDocsUrl: undefined
  });
  const [closeConsignmentConfirmationDialogVisibility, setCloseConsignmentConfirmationDialogVisibility] = useState(
    false
  );
  const open = !!anchorEl;

  const handleMenuIconClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const element = event.currentTarget as HTMLDivElement;
    const links: IConsignmentDocumentUrls = {
      manifestUrlIE: undefined,
      manifestUrlUK: undefined,
      vatInvoiceUrlIE: undefined,
      vatInvoiceUrlUK: undefined,
      customsInvoiceUrlIE: undefined,
      customsInvoiceUrlUK: undefined,
      transitDocsUrl: undefined
    };
    if (isLvc) {
      if (consignmentId) {
        const consignmentLink = '/consignment/' + consignmentId + '/parcels/';
        setConsignmentUrl(consignmentLink);
      }
    } else {
      if (typeof invoiceKeyIE !== 'undefined') {
        links.vatInvoiceUrlIE = await getFileURL({ key: invoiceKeyIE });
      }
      if (typeof customsInvoiceKeyIE !== 'undefined') {
        links.customsInvoiceUrlIE = await getFileURL({ key: customsInvoiceKeyIE });
      }
      if (typeof manifestKeyIE !== 'undefined') {
        links.manifestUrlIE = await getFileURL({ key: manifestKeyIE });
      }
    }
    if (typeof invoiceKeyUK !== 'undefined') {
      links.vatInvoiceUrlUK = await getFileURL({ key: invoiceKeyUK });
    }
    if (typeof customsInvoiceKeyUK !== 'undefined') {
      links.customsInvoiceUrlUK = await getFileURL({ key: customsInvoiceKeyUK });
    }
    if (typeof manifestKeyUK !== 'undefined') {
      links.manifestUrlUK = await getFileURL({ key: manifestKeyUK });
    }
    if (typeof transitDocKey !== 'undefined') {
      links.transitDocsUrl = await getFileURL({ key: transitDocKey });
      console.log('Got transit docs key.');
      console.log(links.transitDocsUrl);
    } else {
      console.log('transit docs key is undefined');
    }
    setUrls({ ...urls, ...links });
    setAnchorEl(element);
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const vesselNumberToValidate = vesselNumber.toString();
  const history = useHistory();

  const RouteToEditVesselNumberPage = () => {
    history.push(reverse(URLS.EDIT_VESSEL_NUMER, { consignmentId, vesselNumberToValidate }));
  };

  const closeHandler = async () => {
    try {
      handleToggleConsignmentConfirmationDialogVisibility();
      const isValid = validateVesselNumber(vesselNumber.toString());
      if (purpose === 'INBOUND' && !isValid) {
        RouteToEditVesselNumberPage();
      } else {
        dispatchConsignments({ type: 'close', close: [consignmentId] });
        await closeConsignment(consignmentId);
        setAnchorEl(null);
        closeEvent();
      }
    } catch (e) {
      if (e.response) {
        // Request has not been aborted
        console.error(e);

        setError(HTTP_ERROR_MESSAGE);
        setTimeout(() => {
          closeEvent();
        }, 3000);
      } else {
        // Request has been aborted or network error occurred
        window.location.reload();
      }
    }
  };

  const handleToggleConsignmentConfirmationDialogVisibility = () => {
    setCloseConsignmentConfirmationDialogVisibility(!closeConsignmentConfirmationDialogVisibility);
  };

  const menuItemsProps = [
    {
      url: urls.vatInvoiceUrlIE,
      label: 'Open VAT Commercial Invoice (IE)',
      show: !isLvc,
      target: '_blank'
    },
    {
      url: urls.vatInvoiceUrlUK,
      label: 'Open VAT Commercial Invoice (UK)',
      show: true,
      target: '_blank'
    },
    {
      url: urls.customsInvoiceUrlIE,
      label: 'Open Customs Commercial Invoice (IE)',
      show: !isLvc,
      target: '_blank'
    },
    {
      url: urls.customsInvoiceUrlUK,
      label: 'Open Customs Commercial Invoice (UK)',
      show: true,
      target: '_blank'
    },
    {
      url: urls.manifestUrlIE,
      label: 'Open Packing List (IE)',
      show: !isLvc,
      target: '_blank'
    },
    {
      url: urls.manifestUrlUK,
      label: 'Open Packing List (UK)',
      show: true,
      target: '_blank'
    },
    {
      url: consignmentUrl,
      label: 'Open Parcel View',
      show: isLvc,
      target: ''
    },
    {
      url: urls.transitDocsUrl,
      label: 'Open Transit Document',
      show: true,
      target: '_blank'
    }
  ];

  let errorMsg = <></>;
  if (error !== '') {
    errorMsg = <StatusPopup text={error} type="ERROR" />;
  }

  if (status === 'CLOSING' || status === 'SENDING_XML_DECLARATIONS') {
    return <CircularProgress />;
  } else {
    return (
      <div onClick={handleMenuIconClick}>
        <IconButton>
          <MoreVertIcon />
        </IconButton>
        <Popover
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuList>
            {status === 'CLOSED' ? (
              menuItemsProps
                .filter((menuItemsProp) => menuItemsProp.show)
                .map((menuItemsProp: IMenuItemsProps, key: number) => (
                  <MenuItem
                    onClick={handleCloseMenu}
                    key={key}
                    className={menuItemsProp.url ? '' : classes.disabledMenuItem}
                  >
                    <Link
                      href={menuItemsProp.url}
                      target={menuItemsProp.target}
                      color="inherit"
                      className={menuItemsProp.url ? classes.navLink : classes.disabledNavLink}
                    >
                      <IconButton disabled color="primary">
                        <ReceiptIcon />
                      </IconButton>
                      {menuItemsProp.label}
                    </Link>
                  </MenuItem>
                ))
            ) : (
              <Fragment>
                {errorMsg}
                <MenuItem
                  className={
                    status === 'CLOSING' || status === 'SENDING_XML_DECLARATIONS' ? classes.disabledMenuItem : ''
                  }
                >
                  <Link
                    color="inherit"
                    href={reverse(URLS.EDIT_CONSIGNMENT, { consignmentId })}
                    className={status === 'CLOSING' ? classes.disabledNavLink : classes.navLink}
                  >
                    <IconButton disabled>
                      <EditSharp className={classes.iconButton} />
                    </IconButton>
                    Edit consignment info
                  </Link>
                </MenuItem>
                <MenuItem
                  className={
                    status === 'CREATED' || status === 'SENDING_XML_DECLARATIONS' || status === 'CLOSING'
                      ? classes.disabledMenuItem
                      : ''
                  }
                >
                  <Typography
                    className={
                      status === 'CREATED' || status === 'SENDING_XML_DECLARATIONS' || status === 'CLOSING'
                        ? classes.disabledNavLink
                        : ''
                    }
                    onClick={handleToggleConsignmentConfirmationDialogVisibility}
                  >
                    <IconButton disabled>
                      <CheckCircleOutlineIcon className={classes.iconButton} />
                    </IconButton>
                    Close/Seal consignment
                  </Typography>
                  <ConfirmationDialog
                    open={closeConsignmentConfirmationDialogVisibility}
                    onClose={handleToggleConsignmentConfirmationDialogVisibility}
                    onConfirm={closeHandler}
                    title="Confirm"
                    text="Are you sure you want to close/seal consignment?"
                    confirmButtonLabel="Seal & Print"
                    cancelButtonLabel="Cancel"
                  />
                </MenuItem>
              </Fragment>
            )}
          </MenuList>
        </Popover>
      </div>
    );
  }
};
